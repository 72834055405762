import React, {useEffect, useState} from 'react'
import {Card, Tooltip, OverlayTrigger, Button, Form} from 'react-bootstrap'
import {OrderRutinLogsModel} from '../../models/MasterModel'
import axios from 'axios'
import LocalTable from '../../parts/LocalTable'
import Swal from 'sweetalert2'
import moment from 'moment'

export default function OrderRutinDash() {
  const [logs, setLogs] = useState<OrderRutinLogsModel[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [cnt, setCnt] = useState(0)
  useEffect(() => {
    setIsLoading(true)
    axios
      .get('/dashboard/order-rutin-logs')
      .then((d: {data: OrderRutinLogsModel[]}) => setLogs(d.data))
      .finally(() => setIsLoading(false))
  }, [cnt])
  const checkItem = (o: OrderRutinLogsModel, act: boolean) => {
    Swal.fire({
      title: act ? 'Tandai Sudah Order?' : 'Tandai Belum Order?',
      text: o.keterangan,
      icon: 'question',
      showCancelButton: true,
      preConfirm: () =>
        axios.post('/barang/order-rutin/mark/' + o.id, {done: act}).then(() => {
          let lgs = [...logs]
          let cm: any = lgs.find((x) => x.id === o.id)
          cm.done = act
          if (act) {
            cm.done_at = moment().format('YYYY-MM-DD HH:mm:ss')
          } else {
            cm.done_at = null
          }
          setLogs(lgs)
        }),
    })
  }
  const cols = [
    {
      Header: 'Status',
      accessor: 'done',
      Cell: (v: any) => (
        <div className='d-flex justify-content-center'>
          <div className='d-flex justify-content-center align-items-center'>
            <Form.Check
              onChange={(e) => checkItem(v.row.original, e.target.checked)}
              label={v.value ? 'Sudah Order' : 'Belum Order'}
              isValid={v.value}
              isInvalid={!v.value}
              checked={v.value}
            />
          </div>
        </div>
      ),
    },
    {
      Header: 'Tanggal',
      accessor: 'tgl',
    },
    {
      Header: 'Keterangan',
      accessor: 'keterangan',
    },
    {
      Header: 'Tgl Check',
      accessor: 'done_at',
      Cell: (v: any) => <div>{v.value ? moment(v.value).format('DD/MM/YYYY HH:mm') : '-'}</div>,
    },
  ]
  return (
    <div>
      <Card className='mb-3'>
        <Card.Body>
          <Card.Title>Order Barang Rutin</Card.Title>
          <Button variant='primary' disabled={isLoading} onClick={() => setCnt(cnt + 1)}>
            <i className='fas fa-sync me-2'></i>Refresh
          </Button>
          <div className='table-responsive'>
            <LocalTable
              data={logs}
              isLoading={isLoading}
              tblClass='table-striped gs-2 gy-2 gx-2 mb-3'
              columns={cols}
            />
          </div>
        </Card.Body>
      </Card>
    </div>
  )
}
