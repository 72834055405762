import axios from 'axios'
import moment from 'moment'
import React, {useEffect, useState} from 'react'
import {Col, Container, Row} from 'react-bootstrap'
import {toast} from 'react-toastify'
import Swal from 'sweetalert2'
import {getFBToken} from '../../../firebase'
import {PageTitle} from '../../../_metronic/layout/core'
import {useAuth, UserModel} from '../../modules/auth'
import {can} from '../../setups/constant'
import ControlStockDash from './ControlStockDash'
import {osName, osVersion, browserName, browserVersion, deviceType} from 'react-device-detect'
import {Link} from 'react-router-dom'
import RekapCostDash from './RekapCostDash'
import {KTSVG} from '../../../_metronic/helpers'
import ReqDash from './ReqDash'
import OrderRutinDash from './OrderRutinDash'

export default function Dashboard() {
  axios.defaults.baseURL = process.env.REACT_APP_API_URL
  const auth = useAuth()
  const user: UserModel | undefined = auth.currentUser
  const [notifGranted, setNotifGranted] = useState(false)

  useEffect(() => {
    const cekPer = async () => {
      const ret = (await Notification.permission) === 'granted'
      return ret
    }
    const cek = new Promise<boolean>((res) => {
      res(cekPer())
    })
    cek.then((d) => {
      setNotifGranted(d)
    })
  }, [])

  const registerDevice = () => {
    Swal.fire({
      icon: 'question',
      title: 'Register this device for notification?',
      showCancelButton: true,
      confirmButtonText: 'Register',
    }).then((d) => {
      if (d.isConfirmed) {
        Swal.fire({
          title: 'Loading',
          icon: 'info',
          html: '<span id="spanid">Loading</span>',
          allowOutsideClick: false,
          allowEscapeKey: false,
          showConfirmButton: false,
        })
        Swal.showLoading()
        let el = document.getElementById('spanid')
        if (el) {
          el.innerHTML = 'Please allow notification access'
          reqPermission()
            .then((res) => {
              el!.innerHTML = res
            })
            .finally(() => {
              el!.innerHTML = 'Getting FCM Token'
              getFBToken()
                .then((token: string) => {
                  el!.innerHTML = 'Success, fcm token: ' + token
                  setTimeout(() => {
                    el!.innerHTML = 'Registering to server'
                    axios
                      .post('/register-device', {
                        type: 'fcm',
                        name: `${deviceType} ${osName} ${osVersion} | ${browserName} ${browserVersion}`,
                        token: token,
                      })
                      .then(() => {
                        toast.success('Notification registration success!')
                        setNotifGranted(true)
                      })
                      .catch((er) => toast.error('Error registering to server'))
                      .finally(() => Swal.close())
                  }, 1000)
                })
                .catch((er) => toast.error(er))
            })
        }
      }
    })
  }
  const reqPermission = () =>
    new Promise<string>((res, rej) => {
      if (!('Notification' in window)) {
        rej('Browser anda tidak mendukung fitur notifikasi. Silahkan install browser terbaru.')
      } else if (Notification.permission === 'granted') {
        res('Notification registered')
      } else if (Notification.permission !== 'denied') {
        Notification.requestPermission((permission) => {
          if (permission === 'granted') {
            res('Notification registered')
          } else {
            rej('Anda tidak mengizinkan notifikasi di situs ini')
          }
        })
      }
    })

  return (
    <div>
      <PageTitle>DASHBOARD</PageTitle>
      <Row>
        <Col>
          <div className='bg-light w-100'>
            <Container className='p-5'>
              <h1 className='display-4'>Welcome, {user?.name}</h1>
              <p className='lead'>
                <KTSVG
                  path='/media/icons/duotune/files/fil002.svg'
                  className='svg-icon-primary svg-icon-2x me-2'
                />
                {moment().format('DD MMMM YYYY')}
              </p>
              <hr className='my-4' />
              <p>
                <KTSVG
                  path='/media/icons/duotune/communication/com003.svg'
                  className={`svg-icon-${notifGranted ? 'success' : 'danger'} svg-icon-2hx me-2`}
                />
                Device notification:{' '}
                {notifGranted ? (
                  <span>
                    <b className='text-success me-2'>ON</b>
                    <Link to={''} onClick={() => registerDevice()}>
                      Re-Register Notification
                    </Link>
                  </span>
                ) : (
                  <span>
                    <b className='text-danger me-2'>OFF</b>
                    <Link to={''} onClick={() => registerDevice()}>
                      Register Notification
                    </Link>
                  </span>
                )}
              </p>
            </Container>
          </div>
        </Col>
      </Row>
      <Row>
        <Col className='mb-3'>
          <ReqDash />
        </Col>
      </Row>
      {can('Inv Admin', user) ? (
        <Row>
          <Col md={12} className='mb-3'>
            <ControlStockDash />
          </Col>
          <Col md={12} className='mb-3'>
            <OrderRutinDash />
          </Col>
          <Col md={12} className='mb-3'>
            <RekapCostDash />
          </Col>
        </Row>
      ) : (
        ''
      )}
    </div>
  )
}
