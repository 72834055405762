import {useEffect, useState} from 'react'
import {Outlet} from 'react-router-dom'
import {AsideDefault} from './components/aside/AsideDefault'
import {Footer} from './components/Footer'
import {HeaderWrapper} from './components/header/HeaderWrapper'
import {ScrollTop} from './components/ScrollTop'
import {Content} from './components/Content'
import {PageDataProvider} from './core'
import {useLocation} from 'react-router-dom'
import {ThemeModeProvider} from '../partials'
import {MenuComponent} from '../assets/ts/components'
import {onMessageListener} from '../../firebase.js'
import {toast, ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {CardComp} from '../../app/setups/helper_components'
import Echo from 'laravel-echo'
import {getAuth, useAuth} from '../../app/modules/auth'
import axios from 'axios'

const MasterLayout = () => {
  const auth = getAuth()
  const ath = useAuth()
  const user = ath.currentUser
  const [isOnline, setIsOnline] = useState(true)

  useEffect(() => {
    const ech = new Echo({
      broadcaster: 'pusher',
      wsHost: process.env.REACT_APP_WS_HOST,
      wsPort: process.env.REACT_APP_WS_PORT,
      key: process.env.REACT_APP_WS_KEY,
      cluster: 'mt1',
      forceTLS: true,
      authEndpoint: process.env.REACT_APP_API_URL + '/broadcasting/auth',
      auth: {
        headers: {
          Authorization: 'Bearer ' + auth?.api_token,
        },
      },
    })
    ech.private('App.Models.User.' + user?.id).notification((notif: any) => {
      const aud = new Audio('/media/chime.wav')
      var playPromise = aud.play()
      if (playPromise !== undefined) {
        playPromise.then((_) => {}).catch((error) => {})
      }
      if (!isOnline && Notification.permission === 'granted') {
        const notification = new Notification(notif.notification.title, {
          icon: process.env.REACT_APP_URL + '/logo256.png',
          body: notif.notification.body,
          image: notif.notification.image,
        })
        if (notif.notification.link) {
          notification.onclick = () =>
            function () {
              window.open(notif.notification.link)
            }
        }
      } else {
        setTimeout(() => {
          toast(
            <CardComp
              title={notif.notification.title}
              image={notif.notification.image}
              body={notif.notification.body}
            />
          )
        }, 0)
      }
      axios.post(process.env.REACT_APP_API_URL + '/mark-delivery', {
        identifier: notif.notification.identifier,
      })
    })
    return () => {
      ech.leave('App.Models.User.' + user?.id)
    }
  }, [auth?.api_token, isOnline, user?.id])

  useEffect(() => {
    window.addEventListener('focus', () => setIsOnline(true))
    window.addEventListener('blur', () => setIsOnline(false))
    return () => {
      window.removeEventListener('focus', () => setIsOnline(true))
      window.removeEventListener('blur', () => setIsOnline(false))
    }
  }, [])
  onMessageListener().then((e: any) => {
    const aud = new Audio('/media/chime.wav')
    var playPromise = aud.play()
    if (playPromise !== undefined) {
      playPromise.then((_) => {}).catch((error) => {})
    }
    if (!isOnline && Notification.permission === 'granted') {
      const notification = new Notification(e.notification.title, {
        icon: process.env.REACT_APP_URL + '/logo256.png',
        body: e.notification.body,
        image: e.notification.image,
      })
      if (e.notification.link) {
        notification.onclick = () =>
          function () {
            window.open(e.notification.link)
          }
      }
    } else {
      setTimeout(() => {
        toast(
          <CardComp
            title={e.notification.title}
            image={e.notification.image}
            body={e.notification.body}
          />
        )
      }, 0)
    }
  })
  const location = useLocation()
  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [location.key])

  return (
    <PageDataProvider>
      <ThemeModeProvider>
        <div className='page d-flex flex-row flex-column-fluid'>
          <AsideDefault />
          <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
            <HeaderWrapper />

            <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
              <div className='post d-flex flex-column-fluid' id='kt_post'>
                <Content>
                  <ToastContainer />
                  <Outlet />
                </Content>
              </div>
            </div>
            <Footer />
          </div>
        </div>

        <ScrollTop />
      </ThemeModeProvider>
    </PageDataProvider>
  )
}

export {MasterLayout}
