import axios from 'axios'
import moment from 'moment'
import React, {Fragment, useEffect, useState} from 'react'
import {Badge, Tooltip, Card, OverlayTrigger, Modal} from 'react-bootstrap'
import {NumericFormat} from 'react-number-format'
import {KTSVG} from '../../../_metronic/helpers'
import {ReqBarangHeaderModel, ReqBarangStatusApprovalModel} from '../../models/TransModel'
import LocalTable from '../../parts/LocalTable'

export default function ReqDash() {
  axios.defaults.baseURL = process.env.REACT_APP_API_URL
  const [reqs, setReqs] = useState<ReqBarangHeaderModel[]>([])
  const [mdDetail, setMdDetail] = useState(false)
  const [mdApproval, setMdApproval] = useState(false)
  const [selectedItem, setSelectedItem] = useState<ReqBarangHeaderModel>()
  useEffect(() => {
    axios.get('/dashboard/requests').then((d: {data: ReqBarangHeaderModel[]}) => {
      setReqs(d.data)
    })
  }, [])
  const closeMdDetail = () => {
    setMdDetail(false)
    setTimeout(() => {
      setSelectedItem(undefined)
    }, 300)
  }
  const closeMdApproval = () => {
    setMdApproval(false)
    setTimeout(() => {
      setSelectedItem(undefined)
    }, 300)
  }
  const cols = [
    {
      Header: 'Act',
      accessor: 'id',
      id: 'act',
      Cell: (v: any) => (
        <div className='d-flex justify-content-center'>
          <OverlayTrigger
            placement='right'
            delay={{show: 250, hide: 400}}
            overlay={<Tooltip id='tooltip-id'>Lihat Detail Barang</Tooltip>}
          >
            <div
              onClick={() => {
                setSelectedItem(v.row.original)
                setTimeout(() => {
                  setMdDetail(true)
                }, 1)
              }}
              className='me-2'
              style={{cursor: 'pointer'}}
            >
              <KTSVG
                path='/media/icons/duotune/ecommerce/ecm009.svg'
                className='svg-icon-primary svg-icon-2x'
              />
            </div>
          </OverlayTrigger>
          <OverlayTrigger
            placement='right'
            delay={{show: 250, hide: 400}}
            overlay={<Tooltip id='tooltip-id'>History Approval</Tooltip>}
          >
            <div
              onClick={() => {
                setSelectedItem(v.row.original)
                setTimeout(() => {
                  setMdApproval(true)
                }, 1)
              }}
              className='me-2'
              style={{cursor: 'pointer'}}
            >
              <KTSVG
                path='/media/icons/duotune/abstract/abs027.svg'
                className='svg-icon-info svg-icon-2x'
              />
            </div>
          </OverlayTrigger>
        </div>
      ),
    },
    {
      Header: '#',
      accessor: 'id',
    },
    {
      Header: 'Tgl Pengajuan',
      accessor: 'tgl',
    },
    {
      Header: 'Dept Dituju',
      accessor: 'cost.description',
    },
    {
      Header: 'Status',
      accessor: 'status_approval',
      Cell: (v: {value: ReqBarangStatusApprovalModel}) => (
        <>
          {v.value.status === 'approved' ? (
            <Badge bg='success'>Approved</Badge>
          ) : v.value.status === 'pending' ? (
            <Badge bg='warning'>Pending</Badge>
          ) : v.value.status === 'rejected' ? (
            <Badge bg='danger'>Rejected</Badge>
          ) : (
            v.value.status
          )}
        </>
      ),
    },
  ]
  if (reqs.length) {
    return (
      <Fragment>
        <Card className='mb-3'>
          <Card.Body>
            <Card.Title>My Requests</Card.Title>
            <div className='table-responsive'>
              <LocalTable columns={cols} data={reqs} tblClass='table-striped table-hover' />
            </div>
          </Card.Body>
        </Card>
        {selectedItem ? (
          <Fragment>
            <Modal size='lg' centered show={mdDetail} onHide={closeMdDetail}>
              <Modal.Header closeButton>
                <Modal.Title>Detail Barang</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <table className='table table-striped gs-1 gx-1 gy-1 border'>
                  <thead>
                    <tr className='border text-center' style={{fontWeight: 'bold'}}>
                      <th className='border text-center' style={{fontWeight: 'bold'}}>
                        Barang
                      </th>
                      <th className='border text-center' style={{fontWeight: 'bold'}}>
                        Qty
                      </th>
                      <th className='border text-center' style={{fontWeight: 'bold'}}>
                        Received
                      </th>
                      <th className='border text-center' style={{fontWeight: 'bold'}}>
                        Harga Satuan
                      </th>
                      <th className='border text-center' style={{fontWeight: 'bold'}}>
                        Harga Total
                      </th>
                      <th className='border text-center' style={{fontWeight: 'bold'}}>
                        Keterangan
                      </th>
                      <th className='border text-center' style={{fontWeight: 'bold'}}>
                        Tgl Dibutuhkan
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedItem.details?.map((v, i) => (
                      <tr className='border' key={i}>
                        <td className='border'>{v.barang?.nama_barang}</td>
                        <td className='border'>
                          <NumericFormat
                            value={v.qty_approved}
                            displayType='text'
                            thousandSeparator=','
                          />
                        </td>
                        <td className='border'>
                          <NumericFormat
                            value={v.qty_received}
                            displayType='text'
                            thousandSeparator=','
                          />
                        </td>
                        <td className='border'>
                          <NumericFormat
                            value={v.harga_satuan}
                            displayType='text'
                            thousandSeparator=','
                          />
                        </td>
                        <td className='border'>
                          <NumericFormat
                            value={v.qty_approved * v.harga_satuan}
                            displayType='text'
                            thousandSeparator=','
                          />
                        </td>
                        <td className='border'>{v.comment}</td>
                        <td className='border'>
                          {v.tgl_dibutuhkan ? moment(v.tgl_dibutuhkan).format('DD MMM YYYY') : '-'}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    <tr className='border'>
                      <td colSpan={4} className='border'>
                        <b>TOTAL</b>
                      </td>
                      <td className='border'>
                        <b>
                          <NumericFormat
                            value={selectedItem.details?.reduce<number>((a, b) => {
                              return a + b.harga_satuan * b.qty_approved
                            }, 0)}
                            displayType='text'
                            thousandSeparator=','
                          />
                        </b>
                      </td>
                      <td className='border'></td>
                    </tr>
                  </tfoot>
                </table>
              </Modal.Body>
            </Modal>
            <Modal centered show={mdApproval} onHide={closeMdApproval}>
              <Modal.Header closeButton>
                <Modal.Title>History Approval</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <table className='table table-striped gs-1 gx-1 gy-1 border'>
                  <thead>
                    <tr className='border text-center' style={{fontWeight: 'bold'}}>
                      <th className='border text-center' style={{fontWeight: 'bold'}}>
                        Waktu
                      </th>
                      <th className='border text-center' style={{fontWeight: 'bold'}}>
                        Type
                      </th>
                      <th className='border text-center' style={{fontWeight: 'bold'}}>
                        User
                      </th>
                      <th className='border text-center' style={{fontWeight: 'bold'}}>
                        Ket.
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedItem.status_approval?.history?.map((v, i) => (
                      <tr key={i} className='border'>
                        <td>{moment(v.created_at).format('DD MMMM YYYY HH:mm')}</td>
                        <td>{v.type}</td>
                        <td>{v.user?.name}</td>
                        <td>{v.comment}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Modal.Body>
            </Modal>
          </Fragment>
        ) : (
          ''
        )}
      </Fragment>
    )
  } else {
    return <Fragment></Fragment>
  }
}
