import axios from 'axios'
import moment, {Moment} from 'moment'
import React, {useEffect, useState} from 'react'
import {Card, Col, Nav, Row, Spinner, Tab} from 'react-bootstrap'
import ReactDatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.min.css'
import {errCatch} from '../../setups/constant'
import {NumericFormat} from 'react-number-format'
import {KTSVG} from '../../../_metronic/helpers'

export default function RekapCostDash() {
  axios.defaults.baseURL = process.env.REACT_APP_API_URL
  const [RCDashData, setRCDashData] = useState<RekapCostDashModel[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [date, setDate] = useState<Moment>(moment())
  useEffect(() => {
    setIsLoading(true)
    axios
      .post('/dashboard/rekap-cost', {
        date,
      })
      .then((d: {data: RekapCostDashModel[]}) => {
        setRCDashData(d.data)
      })
      .finally(() => setIsLoading(false))
      .catch((er) => errCatch(er))
  }, [date])

  return (
    <div>
      <Card>
        <Card.Body>
          <Card.Title className='text-center'>
            Cost Recap{' '}
            <ReactDatePicker
              className='btn btn-outline-primary'
              dateFormat={'MMMM yyyy'}
              showMonthYearPicker
              showYearDropdown={true}
              dropdownMode={'select'}
              onChange={(e) => (e ? setDate(moment(e)) : '')}
              selected={date.toDate()}
            />
          </Card.Title>
          {isLoading ? (
            <div className='d-flex justify-content-center'>
              <Spinner animation='border' variant='primary' role='status' />
            </div>
          ) : (
            <Tab.Container defaultActiveKey={0}>
              <Row>
                <Col sm={2}>
                  <Nav variant='pills' className='flex-column'>
                    {RCDashData.map((v, i) => (
                      <Nav.Item key={i}>
                        <Nav.Link eventKey={i}>{v.dept}</Nav.Link>
                      </Nav.Item>
                    ))}
                  </Nav>
                </Col>
                <Col sm={10}>
                  <Tab.Content>
                    {RCDashData.map((v, i) => (
                      <Tab.Pane eventKey={i} key={i}>
                        <div className='w-100 text-center mb-3'>
                          <span className='text-primary display-1'>{v.dept}</span>
                        </div>
                        <Row>
                          <Col md={6} sm={12} className='mb-3'>
                            <Card className='border'>
                              <Card.Body className='text-center'>
                                <KTSVG
                                  path='/media/icons/duotune/coding/cod006.svg'
                                  className='svg-icon-primary svg-icon-3hx'
                                />
                                <h3>Requests</h3>
                                <h1>
                                  <NumericFormat
                                    thousandSeparator=','
                                    displayType='text'
                                    value={v.req_count}
                                  />
                                </h1>
                              </Card.Body>
                            </Card>
                          </Col>
                          <Col md={6} sm={12} className='mb-3'>
                            <Card className='border'>
                              <Card.Body className='text-center'>
                                <KTSVG
                                  path='/media/icons/duotune/ecommerce/ecm009.svg'
                                  className='svg-icon-info svg-icon-3hx'
                                />
                                <h3>Items Requested</h3>
                                <h1>
                                  <NumericFormat
                                    thousandSeparator=','
                                    displayType='text'
                                    value={v.item_count}
                                  />
                                </h1>
                              </Card.Body>
                            </Card>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12} className='mb-3'>
                            <Card className='border'>
                              <Card.Body className='text-center'>
                                <KTSVG
                                  path='/media/icons/duotune/finance/fin010.svg'
                                  className='svg-icon-warning svg-icon-5x'
                                />
                                <h3>Total Cost</h3>
                                <h1>
                                  Rp.{' '}
                                  <NumericFormat
                                    thousandSeparator=','
                                    displayType='text'
                                    value={v.total_cost}
                                  />
                                </h1>
                              </Card.Body>
                            </Card>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12} className='mb-3'>
                            <Card className='border'>
                              <Card.Body className='text-center'>
                                <h3>Managers</h3>
                                <Row>
                                  {v.manager.map((v, i) => (
                                    <Col key={i} md={6}>
                                      <img
                                        height={70}
                                        alt={v.name}
                                        src={
                                          process.env.REACT_APP_URL +
                                          '/storage/photos/' +
                                          v.username +
                                          '.JPG'
                                        }
                                        className='rounded'
                                      />
                                      <h1>{v.name}</h1>
                                      <h5 className='text-muted'>
                                        Has Approved {v.count} request(s) for total of
                                      </h5>
                                      <h3>
                                        Rp.{' '}
                                        <NumericFormat
                                          thousandSeparator=','
                                          displayType='text'
                                          value={v.cost}
                                        />
                                      </h3>
                                    </Col>
                                  ))}
                                </Row>
                              </Card.Body>
                            </Card>
                          </Col>
                        </Row>
                      </Tab.Pane>
                    ))}
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          )}
        </Card.Body>
      </Card>
    </div>
  )
}

interface RekapCostDashModel {
  dept: string
  item_count: number
  req_count: number
  total_cost: number
  manager: {
    username: string
    name: string
    count: number
    cost: number
  }[]
}
