import {lazy, FC, Suspense, Fragment} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import Dashboard from '../pages/dashboard'
import {useAuth} from '../modules/auth'
import {can} from '../setups/constant'

const PrivateRoutes = () => {
  const auth = useAuth()
  const user = auth.currentUser

  const UserPage = lazy(() => import('../pages/users'))
  const ChangePassword = lazy(() => import('../pages/users/ChangePassword'))
  const MasterBarang = lazy(() => import('../pages/inventory/master_barang'))
  const ReceiptBarang = lazy(() => import('../pages/inventory/receipt'))
  const PengeluaranBarang = lazy(() => import('../pages/inventory/pengeluaran'))
  const KeluarkanBarang = lazy(() => import('../pages/inventory/pengeluaran/KeluarkanBarang'))
  const RequestBarang = lazy(() => import('../pages/inventory/request_barang'))
  const ApprovalInventory = lazy(() => import('../pages/inventory/approval_inventory'))
  const Computer = lazy(() => import('../pages/computers'))
  const ComputerSoftwares = lazy(() => import('../pages/computers/computer/ComputerSoftwares'))
  const ComputerCheck = lazy(() => import('../pages/computers/computer/ComputerCheck'))
  const OrderRutin = lazy(() => import('../pages/inventory/order_rutin'))
  const PeminjamanLaptop = lazy(() => import('../pages/peminjaman/Index'))
  const PeminjamanBarang = lazy(() => import('../pages/peminjaman/Barang'))
  const ApprovalInventoryTmp = lazy(
    () => import('../pages/inventory/approval_inventory/TmpApproval')
  )

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<Dashboard />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        <Route
          path='/change-password'
          element={
            <SuspensedView>
              <ChangePassword />
            </SuspensedView>
          }
        />
        {/* Lazy Modules */}
        {/* Inventory */}
        <Route
          path='/inventory/request'
          element={
            <SuspensedView>
              <RequestBarang />
            </SuspensedView>
          }
        />
        <Route
          path='/peminjamans'
          element={
            <SuspensedView>
              <PeminjamanLaptop />
            </SuspensedView>
          }
        />
        <Route
          path='/peminjaman/barang'
          element={
            <SuspensedView>
              <PeminjamanBarang />
            </SuspensedView>
          }
        />
        {can('IT', user) ? (
          <Fragment>
            <Route
              path='/computers'
              element={
                <SuspensedView>
                  <Computer />
                </SuspensedView>
              }
            />
            <Route
              path='/computers/:id/softwares'
              element={
                <SuspensedView>
                  <ComputerSoftwares />
                </SuspensedView>
              }
            />
            <Route
              path='/computers/:id/checks'
              element={
                <SuspensedView>
                  <ComputerCheck />
                </SuspensedView>
              }
            />
          </Fragment>
        ) : (
          ''
        )}
        {can('TmpAdminManager', user) ? (
          <Route
            path='/inventory/tmp/approval'
            element={
              <SuspensedView>
                <ApprovalInventoryTmp />
              </SuspensedView>
            }
          />
        ) : (
          ''
        )}
        {can('Manager', user) ? (
          <Route
            path='/inventory/approval'
            element={
              <SuspensedView>
                <ApprovalInventory />
              </SuspensedView>
            }
          />
        ) : (
          ''
        )}
        {can('Inv Admin', user) ? (
          <Fragment>
            <Route
              path='/inventory/order-rutin'
              element={
                <SuspensedView>
                  <OrderRutin />
                </SuspensedView>
              }
            />
            <Route
              path='/inventory/master-barang'
              element={
                <SuspensedView>
                  <MasterBarang />
                </SuspensedView>
              }
            />
            <Route
              path='/inventory/receipt'
              element={
                <SuspensedView>
                  <ReceiptBarang />
                </SuspensedView>
              }
            />
            <Route
              path='/inventory/pengeluaran'
              element={
                <SuspensedView>
                  <PengeluaranBarang />
                </SuspensedView>
              }
            />
            <Route
              path='/inventory/pengeluaran/:id/out'
              element={
                <SuspensedView>
                  <KeluarkanBarang />
                </SuspensedView>
              }
            />
          </Fragment>
        ) : (
          ''
        )}
        {/* User Page */}
        {can('View Users', user) ? (
          <Route
            path='users'
            element={
              <SuspensedView>
                <UserPage />
              </SuspensedView>
            }
          />
        ) : (
          ''
        )}
        {/* Masters */}
        {/* {can('View Masters', user) ? (
          <Fragment>
            {can('View Instances', user) ? (
              <Route
                path='master/instances'
                element={
                  <SuspensedView>
                    <MInstances />
                  </SuspensedView>
                }
              />
            ) : (
              ''
            )}
            {can('View Licenses', user) ? (
              <Route
                path='master/licenses'
                element={
                  <SuspensedView>
                    <MLicenses />
                  </SuspensedView>
                }
              />
            ) : (
              ''
            )}
            <Route
              path='master/machines'
              element={
                <SuspensedView>
                  <MMachines />
                </SuspensedView>
              }
            />
          </Fragment>
        ) : (
          ''
        )} */}
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
