import axios from 'axios'
import React, {useEffect, useState} from 'react'
import {Badge, Card} from 'react-bootstrap'
import LocalTable from '../../parts/LocalTable'
import {errCatch} from '../../setups/constant'

export default function ControlStockDash() {
  axios.defaults.baseURL = process.env.REACT_APP_API_URL
  const [isLoading, setIsLoading] = useState(false)
  const [barangs, setBarangs] = useState<DashBarangModel[]>([])
  useEffect(() => {
    setIsLoading(true)
    axios
      .get('/dashboard/control-stock')
      .then((d: {data: DashBarangModel[]}) => {
        setBarangs(d.data)
      })
      .finally(() => setIsLoading(false))
      .catch((er) => errCatch(er))
  }, [])
  const cols = [
    {
      Header: 'Status',
      accessor: 'color',
      Cell: (v: any) => (
        <div className='d-flex justify-content-center'>
          <Badge pill bg={v.value}>
            ------
          </Badge>
        </div>
      ),
    },
    {
      Header: 'SKU',
      accessor: 'sku',
      disableFilters: false,
    },
    {
      Header: 'Nama Barang',
      accessor: 'nama_barang',
      disableFilters: false,
    },
    {
      Header: 'Min Qty',
      accessor: 'min_qty',
    },
    {
      Header: 'Qty in Stock',
      accessor: 'stock',
      Cell: (v: any) => <b className={'text-' + v.row.original.color}>{v.value}</b>,
    },
  ]
  return (
    <div>
      <Card>
        <Card.Body>
          <Card.Title>Control Stock</Card.Title>
          <div className='table-responsive'>
            <LocalTable
              isLoading={isLoading}
              tblClass='table table-striped gx-1 gy-1 gs-1'
              data={barangs}
              columns={cols}
            />
          </div>
        </Card.Body>
      </Card>
    </div>
  )
}
interface DashBarangModel {
  id: number
  nama_barang: string
  kategori_barang_id: number
  cost_id: string
  satuan: string
  min_qty: number
  sku?: string
  stock: number
  color: string
  percent: number
  created_at?: string
  updated_at?: string
}
