// Import the functions you need from the SDKs you need
import {initializeApp} from 'firebase/app'
import {getMessaging, getToken, onMessage} from 'firebase/messaging'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyC-U6tnugpvtjZT0phc_JCENVQ8sA5oJPQ',
  authDomain: 'yi-online.firebaseapp.com',
  databaseURL: 'https://yi-online.firebaseio.com',
  projectId: 'yi-online',
  storageBucket: 'yi-online.appspot.com',
  messagingSenderId: '488138984847',
  appId: '1:488138984847:web:519c294293ba6edb984ffa',
  measurementId: 'G-C88L1RNXTD',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const messaging = getMessaging(app)
export const getFBToken = () =>
  new Promise((res, rej) => {
    getToken(messaging, {
      vapidKey:
        'BLHzx6SBRCoLBFf-lZTwvl_YaMpwnBLeyqo-w1LNo3_LxSZTzJ_y_ynRTJhwKvriLwTu97e11UeT3dWh59FFKsg',
    })
      .then((currentToken) => {
        if (currentToken) {
          res(currentToken)
        } else {
          rej('No registration token available. Request permission to generate token')
        }
      })
      .catch((er) => {
        rej('An error occured while retrieveing token. ' + er)
      })
  })
export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload)
    })
  })
