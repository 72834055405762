import React from 'react'
import {useTable, usePagination, Column, useSortBy, useFilters} from 'react-table'
import {KTSVG} from '../../_metronic/helpers'
import {matchSorter} from 'match-sorter'
type Props = {
  columns: Column[]
  data: any[]
  isLoading?: boolean
  perPage?: number
  tblClass: string
}

function fuzzyTextFilterFn(rows: string[], filterValue: string) {
  return matchSorter(rows, filterValue)
}

// Define a default UI for filtering
interface DCF {
  column: {
    filterValue: any
    preFilteredRows: any
    setFilter: any
  }
}
function DefaultColumnFilter({column: {filterValue, preFilteredRows, setFilter}}: DCF) {
  const count = preFilteredRows.length

  return (
    <input
      className='form-control form-control-xs my-1'
      value={filterValue || ''}
      onChange={(e) => {
        setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
      }}
      placeholder={`Search ${count} records...`}
    />
  )
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val: any) => !val
export default function LocalTable({
  columns,
  data,
  isLoading = false,
  perPage = 10,
  tblClass,
}: Props) {
  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      disableFilters: true,
      Filter: DefaultColumnFilter,
    }),
    []
  )
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: {pageIndex, pageSize},
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {pageIndex: 0, pageSize: perPage},
    },
    useFilters,
    useSortBy,
    usePagination
  )
  return (
    <div>
      <div className={`overlay ${isLoading ? 'overlay-block' : ''} rounded`}>
        <div className='overlay-wrapper p-5'>
          <table className={`table ${tblClass}`} {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup, i) => (
                <tr
                  key={i}
                  className={'fw-bolder fs-6 text-gray-800'}
                  {...headerGroup.getHeaderGroupProps}
                >
                  {headerGroup.headers.map((col, i) => (
                    <th key={i}>
                      <span {...col.getHeaderProps(col.getSortByToggleProps())}>
                        {col.render('Header')}
                      </span>
                      <span>
                        {col.isSorted ? (
                          col.isSortedDesc ? (
                            <KTSVG
                              path='/media/icons/duotune/arrows/arr004.svg'
                              className='svg-icon-muted ml-1'
                            />
                          ) : (
                            <KTSVG
                              path='/media/icons/duotune/arrows/arr003.svg'
                              className='svg-icon-muted ml-1'
                            />
                          )
                        ) : (
                          ''
                        )}
                      </span>
                      <div>{col.canFilter ? col.render('Filter') : null} </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps}>
              {page.map((row, i) => {
                prepareRow(row)
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    })}
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
        {isLoading ? (
          <div className='overlay-layer rounded bg-dark bg-opacity-5'>
            <div className='spinner-border text-primary' role={'status'}>
              <span className='visually-hidden'>Loading...</span>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
      <small className='text-muted'>
        Total {data.length} data, {pageCount} page{' '}
      </small>
      <ul className='pagination'>
        <li className={'page-item previous ' + (!canPreviousPage ? 'disabled' : '')}>
          <button onClick={() => previousPage()} className='page-link'>
            <i className='previous'></i>
          </button>
        </li>
        {pageIndex - 5 >= 0 ? (
          <li className='page-item'>
            <button onClick={() => gotoPage(pageIndex - 5)} className='page-link'>
              {pageIndex - 4}
            </button>
          </li>
        ) : (
          ''
        )}
        {pageIndex - 4 >= 0 ? (
          <li className='page-item'>
            <button onClick={() => gotoPage(pageIndex - 4)} className='page-link'>
              {pageIndex - 3}
            </button>
          </li>
        ) : (
          ''
        )}
        {pageIndex - 3 >= 0 ? (
          <li className='page-item'>
            <button onClick={() => gotoPage(pageIndex - 3)} className='page-link'>
              {pageIndex - 2}
            </button>
          </li>
        ) : (
          ''
        )}
        {pageIndex - 2 >= 0 ? (
          <li className='page-item'>
            <button onClick={() => gotoPage(pageIndex - 2)} className='page-link'>
              {pageIndex - 1}
            </button>
          </li>
        ) : (
          ''
        )}
        {pageIndex - 1 >= 0 ? (
          <li className='page-item'>
            <button onClick={() => gotoPage(pageIndex - 1)} className='page-link'>
              {pageIndex}
            </button>
          </li>
        ) : (
          ''
        )}
        <li className='page-item active'>
          <button className='page-link' disabled>
            {pageIndex + 1}
          </button>
        </li>
        {canNextPage ? (
          <li className='page-item'>
            <button onClick={() => gotoPage(pageIndex + 1)} className='page-link'>
              {pageIndex + 2}
            </button>
          </li>
        ) : (
          ''
        )}
        {pageCount > pageIndex + 2 ? (
          <li className='page-item'>
            <button onClick={() => gotoPage(pageIndex + 2)} className='page-link'>
              {pageIndex + 3}
            </button>
          </li>
        ) : (
          ''
        )}
        {pageCount > pageIndex + 3 ? (
          <li className='page-item'>
            <button onClick={() => gotoPage(pageIndex + 3)} className='page-link'>
              {pageIndex + 4}
            </button>
          </li>
        ) : (
          ''
        )}
        {pageCount > pageIndex + 4 ? (
          <li className='page-item'>
            <button onClick={() => gotoPage(pageIndex + 4)} className='page-link'>
              {pageIndex + 5}
            </button>
          </li>
        ) : (
          ''
        )}
        <li className={'page-item next ' + (!canNextPage ? 'disabled' : '')}>
          <button onClick={() => nextPage()} className='page-link'>
            <i className='next'></i>
          </button>
        </li>
      </ul>
      <div className='mb-10'>
        Row per Page:
        <div className='form-check form-check-sm form-check-custom'>
          <div className='mx-1'>
            <input
              className='form-check-input'
              onChange={(e) => setPageSize(Number(e.target.value))}
              name='showRad'
              type='radio'
              value='10'
              id='showRad10'
              checked={pageSize === 10}
            />
            <label className='form-check-label' htmlFor='showRad10'>
              10
            </label>
          </div>
          <div className='mx-1'>
            <input
              className='form-check-input'
              onChange={(e) => setPageSize(Number(e.target.value))}
              name='showRad'
              type='radio'
              value='20'
              id='showRad20'
              checked={pageSize === 20}
            />
            <label className='form-check-label' htmlFor='showRad20'>
              20
            </label>
          </div>
          <div className='mx-1'>
            <input
              className='form-check-input'
              onChange={(e) => setPageSize(Number(e.target.value))}
              name='showRad'
              type='radio'
              value='30'
              id='showRad30'
              checked={pageSize === 30}
            />
            <label className='form-check-label' htmlFor='showRad30'>
              30
            </label>
          </div>
          <div className='mx-1'>
            <input
              className='form-check-input'
              onChange={(e) => setPageSize(Number(e.target.value))}
              name='showRad'
              type='radio'
              value='40'
              id='showRad40'
              checked={pageSize === 40}
            />
            <label className='form-check-label' htmlFor='showRad40'>
              40
            </label>
          </div>
          <div className='mx-1'>
            <input
              className='form-check-input'
              onChange={(e) => setPageSize(Number(e.target.value))}
              name='showRad'
              type='radio'
              value='50'
              id='showRad50'
              checked={pageSize === 50}
            />
            <label className='form-check-label' htmlFor='showRad50'>
              50
            </label>
          </div>
        </div>
      </div>
    </div>
  )
}
