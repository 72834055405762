import React, {MouseEventHandler} from 'react'
import {Button} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {KTSVG, toAbsoluteUrl} from '../../_metronic/helpers'

type Props = {
  formId?: string
  children?: React.ReactNode
  loading: boolean
  disabled: boolean
  type?: 'button' | 'submit' | 'reset'
  onClick?: MouseEventHandler
  text?: string
  variant?: string
  icon?: React.ReactNode
}
export const SubmitButton: React.FC<Props> = ({
  formId = '',
  children,
  loading,
  disabled,
  type = 'submit',
  onClick,
  text,
  variant = 'light-success',
  icon = <KTSVG path='/media/icons/duotune/general/gen016.svg' className='svg-icon' />,
}) => {
  const intl = useIntl()
  return (
    <Button
      variant={variant}
      type={type}
      onClick={onClick}
      form={formId}
      data-kt-indicator={loading ? 'on' : 'off'}
      disabled={disabled}
    >
      <span className='indicator-label'>
        {icon} {text ? text : intl.formatMessage({id: 'GEN.SUBMIT'})}
      </span>
      <span className='indicator-progress'>
        Please Wait... <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
      </span>
    </Button>
  )
}

export const CloseButton: React.FC<{onClick: MouseEventHandler}> = ({onClick}) => {
  const intl = useIntl()
  return (
    <Button onClick={onClick} variant='light-danger'>
      <KTSVG path='/media/icons/duotune/abstract/abs012.svg' className='svg-icon' />{' '}
      {intl.formatMessage({id: 'GEN.CLOSE'})}
    </Button>
  )
}
export const CardComp = ({
  image,
  title,
  body,
}: {
  image: string | undefined
  title: string
  body: string
}) => (
  <div>
    <div className='d-flex justify-content-center w-100'>
      {image ? <img src={image} height={100} className='me-2 rounded' alt='' /> : ''}
    </div>
    <div>
      <strong>
        <img src={toAbsoluteUrl('/media/logos/logo256.png')} height={20} className='me-2' alt='' />
        {title}
      </strong>
      <p>{body}</p>
    </div>
  </div>
)
