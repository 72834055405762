/* eslint-disable react/jsx-no-target-blank */
import {useIntl} from 'react-intl'
import {useAuth} from '../../../../app/modules/auth'
import {AsideMenuItem} from './AsideMenuItem'
import {can} from '../../../../app/setups/constant'
import {Fragment} from 'react'

export function AsideMenuMain() {
  const intl = useIntl()
  const auth = useAuth()
  const user = auth.currentUser

  return (
    <>
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Main Menu</span>
        </div>
      </div>
      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/general/gen025.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
      />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Inventory</span>
        </div>
      </div>
      <AsideMenuItem
        to='/inventory/request'
        icon='/media/icons/duotune/ecommerce/ecm001.svg'
        title='Request Barang'
      />
      {can('TmpAdminManager', user) ? (
        <Fragment>
          <AsideMenuItem
            to='/inventory/tmp/approval'
            icon='/media/icons/duotune/general/gen017.svg'
            title='Approval (Temporary)'
          />
        </Fragment>
      ) : (
        ''
      )}
      {can('Manager', user) ? (
        <Fragment>
          <AsideMenuItem
            to='/inventory/approval'
            icon='/media/icons/duotune/general/gen019.svg'
            title='Approval Inventory'
          />
        </Fragment>
      ) : (
        ''
      )}
      {can('Inv Admin', user) ? (
        <Fragment>
          <AsideMenuItem
            to='/inventory/order-rutin'
            icon='/media/icons/duotune/general/gen014.svg'
            title='Order Rutin'
          />
          <AsideMenuItem
            to='/inventory/master-barang'
            icon='/media/icons/duotune/ecommerce/ecm009.svg'
            title='Master Barang'
          />
          <AsideMenuItem
            to='/inventory/receipt'
            icon='/media/icons/duotune/ecommerce/ecm008.svg'
            title='Receipt Barang'
          />
          <AsideMenuItem
            to='/inventory/pengeluaran'
            icon='/media/icons/duotune/ecommerce/ecm006.svg'
            title='Pengeluaran Barang'
          />
        </Fragment>
      ) : (
        ''
      )}
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>IT Hardware</span>
        </div>
      </div>
      {can('IT', user) ? (
        <Fragment>
          <AsideMenuItem
            to='computers'
            icon='/media/icons/duotune/abstract/abs026.svg'
            title='Computers'
          />
        </Fragment>
      ) : (
        ''
      )}
      <AsideMenuItem
        to='peminjamans'
        icon='/media/icons/duotune/electronics/elc004.svg'
        title='Peminjaman Laptop'
      />
      <AsideMenuItem
        to='peminjaman/barang'
        icon='/media/icons/duotune/general/gen054.svg'
        title='Peminjaman Barang'
      />

      {can('View Users', user) ? (
        <Fragment>
          <div className='menu-item'>
            <div className='menu-content pt-8 pb-2'>
              <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Users</span>
            </div>
          </div>
          <AsideMenuItem
            to='users'
            icon='/media/icons/duotune/communication/com006.svg'
            title='Users'
          />
        </Fragment>
      ) : (
        ''
      )}
    </>
  )
}
